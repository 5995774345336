import React from "react";
import { Container, Row, Col } from "reactstrap";


const Features = () => {
  return (
    <section id="policy" className="section policy">
      <Container>
          <p className="text-muted">
            
          1.	SOCIOS
                1.1.	Reconocimiento, protección y ejercicio de los derechos que le asisten a sus accionistas.
                2.	ASPIRANTES Y TRABAJADORES
                2.1.	Envío de información a los trabajadores y a sus familias.
                2.2.	Para los procesos de preselección o selección de personal
                2.3.	Atender adecuadamente las actividades de reclutamiento y selección de los aspirantes a trabajador de la empresa.
                2.4.	Efectuar la admisión formal a la empresa en caso de ser escogido.
                2.5.	Confirmar la información personal que he entregado  mediante el cruce de la misma con bases de datos públicas, centrales y sistemas de prevención de riesgo, compañías especializadas, referencias y contactos.
                2.6.	Adelantar las afiliaciones a las que tiene derecho por ley el trabajador ante fondos de pensiones, EPS, administradoras de riesgos y cajas de compensación familiar.
                2.7.	Entregar la información requerida de sus empleados o sus contratistas y/o empleados de los mismos para que sea autorizado el ingreso a oficinas, empresas o lugares donde se requiera entregar documentación por aspectos de protección en seguridad ocupacional. 
                2.8.	Transferir y/o transmitir los datos personales a entidades y/o autoridades judiciales y/o administrativas, cuando éstos sean requeridos en relación con su objeto social y necesarios para el cumplimiento de sus funciones
                2.9.	Compartir con terceros, algunos de los datos personales de identificación, tales como nombre, apellido, cédula, número de teléfono celular o fijo, dirección de correo electrónico o dirección de domicilio.
                2.10.	Enviar a través de cualquier medio de comunicación, electrónico o físico, conocido o por conocerse, informaciones y notificaciones de carácter genera.
                2.11.	Incorporar los datos personales en las carpetas y archivos donde se relaciona la historia laboral. 
                3.	CONTRATISTAS Y PROVEEDORES
                3.1.	Utilización de imagen personal con fines comerciales, previa autorización y/o suscripción contractual
                3.2.	Para los procesos de preselección o selección contratistas o proveedores
                3.3.	Para la obtención o suministro de bienes y/o servicios requeridos u ofrecidos SERVICIOS.IN S.A.S.
                3.4.	Para enviar y/o solicitar información comercial y de procesos y dar contestación a requerimientos de los proveedores y contratistas.
                3.5.	Confirmar la información personal entregada  mediante el cruce con bases de datos públicas, centrales y sistemas de prevención de riesgo, compañías especializadas, referencias y contactos
                3.6.	Transferir y/o transmitir datos personales a entidades y/o autoridades judiciales y/o administrativas, cuando éstos sean requeridos en relación con su objeto social y necesario para el cumplimiento de sus funciones.
                3.7.	compartir con terceros, algunos de los datos personales de identificación, tales como nombre, apellido, cédula, número de teléfono celular o fijo, dirección de correo electrónico o dirección de domicilio.
                3.8.	Enviar a través de cualquier medio de comunicación, electrónico o físico, conocido o por conocerse, informaciones y notificaciones de carácter general.
                3.9.	Incorporar los datos personales en las carpetas y archivos donde se relaciona el historial.
                3.10.	Envió y recepción de facturas electrónicas y documentos electrónicos.
                4.	CLIENTES Y PUBLICO EN GENERAL
                4.1.	Para él envió de información comercial e invitación a eventos realizados por SERVICIOS.IN S.A.S. a clientes, proveedores y contratistas.
                4.2.	Para la contestación a las peticiones, quejas o reclamos elevados por personas naturales hacia SERVICIOS.IN S.A.S.
                4.3.	Para la obtención o suministro de bienes y/o servicios requeridos u ofrecidos SERVICIOS.IN S.A.S.
                4.4.	Para enviar y/o solicitar información comercial y de procesos y dar contestación a requerimientos de los proveedores y contratistas.
                4.5.	Para enviar, solicitar y recibir información de los acreedores y deudores, incluyendo, pero no limitándose a saldos pendientes, deudas canceladas.
                4.6.	Ejecutar la relación contractual existente con sus clientes. 
                4.7.	Para realizar actividades de mercadeo, investigación y demás propósitos comerciales que no contravengan la legislación vigente en Colombia.
                4.8.	Para la atención de requerimientos judiciales o administrativos y el cumplimiento de mandatos judiciales o legales; 
                4.9.	Para contactar, vía correo electrónico, o por cualquier otro medio, a personas naturales con quienes tiene o ha tenido relación, incluyendo, pero no limitándose a, trabajadores y familiares de éstos, accionistas, consumidores, clientes, distribuidores, proveedores, acreedores y deudores, para las finalidades antes mencionadas.
                4.10.	Enviar información comercial, publicitaria o promocional a sus clientes por medio de correo físico, electrónico, dispositivos móviles (mensajes de texto, whatsapp, Messenger, Facebook, twitter, etc.) o través de cualquier oro método análogo o digital de comunicación creado o por crearse.
                4.11.	Suministrar, compartir, enviar o entregar los datos personales a empresas filiales, vinculadas, subordinadas y/o clientes de SERVICIOS.IN S.A.S. en el evento que dichas empresas requieran la información para los fines acá indicados respecto a los datos. 
                4.12.	Para el envío y recepción de factura electrónica y documentos electrónicos entre las partes.
                5.	CAMARAS DE VIGILANCIA
                5.1.	Las imágenes guardadas por cámaras de vigilancia almacenarán información para seguridad de las personas y de los bienes 
                5.2.	Esta información puede ser utilizada como prueba en cualquier tipo de proceso, ante  cualquier autoridad, siempre y cuando se esté dando cumplimiento a las normas aplicables a cada caso. Se protegerá el derecho a la intimidad y no se colocaran en sitio escondidos o que la afecten.
                5.3.	Las imágenes serán conservadas por un mes de acuerdo a la finalidad de recolección
                SERVICIOS.IN S.A.S., le informa que Si se trata de datos sensibles o de menores, usted tiene el derecho de decidir si suministra o no la información solicitada. 
                Podrá además ejercer, en cualquier momento, sus derechos como titular de los Datos Personales, consagrados en el artículo 8 de la Ley 1518 de 2012, como son los de conocer, actualizar, rectificar, modificar, acceder, solicitar la supresión de mis datos personales, o revocar la autorización otorgada, salvo que tenga algún deber legal de permanecer en la base de datos.

                Para solicitar lo anterior por favor dirigir su comunicación KAGUILAR@SERVICIOS.IN.  Para consultar nuestra política de protección de base de datos ingrese a WWW.SERVICIOS.IN
          </p>
      </Container>
    </section>
  );
};

export default Features;
