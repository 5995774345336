import React from "react";
import { Container, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Fade from "react-reveal/Fade";

const shapes = [
  { duration: 1500, delay: 100 },
  { duration: 1000, delay: 100 },
  { duration: 500, delay: 200 },
  { duration: 500, delay: 200 },
  { duration: 1000, delay: 200 },
  { duration: 1000, delay: 200 },
  { duration: 1000, delay: 100 },
  { duration: 1000, delay: 100 },
  { duration: 1000, delay: 300 }
];

const Heading = () => {
  return (
    <header className="header alter2-header section policy-header">
      <div className="shapes-container">
        <div className="static-shape background-shape-main" />
      </div>

      <Container>
        <Row>
          <Col md="6">
            <span className="rounded-pill shadow-box bg-contrast text-dark bold py-2 px-4">
              <FontAwesomeIcon
                icon={["far", "lightbulb"]}
                className="text-primary mr-2"
              />
              <span className="text-primary">Políticas de</span> Privacidad y Uso
            </span>

            <h1 className="display-4 display-md-2 mt-3">
              <span className="bold">SUMR</span>
            </h1>
            <p className="lead bold text-primary">
              Aviso de &amp;{" "}
              <span className="head-line-2">Privacidad</span>
            </p>

            <p className="text-muted">
                SERVICIOS.IN S.A.S. Identificada con Nit. 900.514.277-4, con domicilio en Calle 100 No. 68b- 53, Bogotá, es Responsable de los Datos Personales que recolecta, almacena, y circula, así como del uso y protección de los mismos de acuerdo con lo que se establece en el presente Aviso de Privacidad. Los Datos Personales en tratamiento, serán utilizados para las siguientes finalidades:
            </p>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default Heading;
